function equalHeight(targetElement) {

        var tallestItem = 0;
        var tE = $(targetElement);

        if(tE.length > 0) {
            tE.height('auto');
            tE.each(function() {
                if($(this).height() > tallestItem) { tallestItem = $(this).height(); }
            });
            tE.height(tallestItem);
        }
    }

function removeHeight(targetElement) {

        $(targetElement).height('auto');

    }

$(document).ready(function() {

	// generic year for copyright
    var d = new Date();
    $('.date-now').html(d.getFullYear());



	function toggleNav() {

	}  



	if(!(/Android|iPhone|iPad|iPod|BlackBerry|Windows Phone/i).test(navigator.userAgent || navigator.vendor || window.opera)){
    	var s = skrollr.init({
    		forceHeight: false,
        	smoothScrolling: false,
        	mobileDeceleration: 0.004
    	});

      skrollr.menu.init(s, {
          animate: true,
          easing: 'outCubic',
          duration: function(currentTop, targetTop) {
              //return 1000;
              //console.log(currentTop + ', ' + targetTop);
              return Math.abs(currentTop - targetTop); // * 10;
          },
          updateUrl: false
      });

      document.addEventListener('orientationchange', function(e) {
        //alert('switch');
        window.scrollTo(0,0);
        //$isotopecontainer.isotope('layout');
        //$isotopecontainercase.isotope('layout');
        s.refresh();
      });


      $(window).resize(function() {
        s.refresh();
      });
    }



    if($('.product-item').length) {

      equalHeight('.product-item');

      $(window).resize(function() { 
         equalHeight('.product-item');
      });
      $(window).resize(function () {
          equalHeight('.product-cat-item');
      });
      $(window).resize(function () {
          equalHeight('.product-item-text');
      });

    } 


    if ($('.product-cat-item').length) {

        equalHeight('.product-item-text');


    }


});

$(window).load(function() {

  $('.hero-items').slick({
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    arrows: true,
    prevArrow: '<button type="button" class="slick-prev"><span class="fa fa-chevron-left"></span></button>',
    nextArrow: '<button type="button" class="slick-next"><span class="fa fa-chevron-right"></span></button>',
  });

});

$(document).ready(function () {
    $('#my-video').backgroundVideo({
        $outerWrap: $('.outer-wrap'),
        pauseVideoOnViewLoss: false,
        parallaxOptions: {
            effect: 1.9
        }
    });

});

function show(target) {
    document.getElementById(target).style.display = 'block';
}

function hide(target) {
    document.getElementById(target).style.display = 'none';
}


